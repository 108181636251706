.share{
  a{
    transition: all 0.5s ease;
    color: $white;
    display: flex;
    align-items: center;
    padding-right: 1rem;
    &:hover{
      opacity: 0.5;
    }
    svg{
      width: 14px;
      height: 14px;
      fill: #fff;
    }
  }
  .youtube{
    svg{
      width: 12px;
      height: 12px;
    }
  }
}