/*
  # Background image with object-fit
*/

// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}

.bg-6 { background-color: rgba($gray-800, .6) }
.bg-light-grey { background-color: #ececec }
.bg-product-sub { background-color: #eaeaea }
.bg-dark{ background-color: #2c2c2c!important }

@include media-breakpoint-up(md) {
	.pxx-md-6 {
		padding-left: rem(6);
		padding-right: rem(6);
	}
}

// # Mosca
.mosca{
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffe500;
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 3;
  svg{
    width: 40px;
    color: #3a3a3a;
  }
}