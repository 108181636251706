/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
	font-display: swap;
  src: local(''),
       url('../fonts/lato-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lato-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
	font-display: swap;
  src: local(''),
       url('../fonts/lato-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lato-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
	font-display: swap;
  src: local(''),
       url('../fonts/lato-v22-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lato-v22-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-400 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
	font-display: swap;
  src: local(''),
       url('../fonts/oswald-v41-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/oswald-v41-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-500 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
	font-display: swap;
  src: local(''),
       url('../fonts/oswald-v40-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/oswald-v40-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
	font-display: swap;
  src: local(''),
       url('../fonts/oswald-v40-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/oswald-v40-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
	font-display: swap;
  src: local(''),
       url('../fonts/oswald-v40-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/oswald-v40-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.ff-lato { font-family: 'Lato' }
.ff-oswald { font-family: 'Oswald' }

$sizes: 12, 13, 15, 14, 16, 17, 18, 20, 22, 24, 25, 26, 30, 32, 40, 42, 45, 50, 60, 70;

@each $size in $sizes {
  .fs-#{$size} {
    font-size: rem($size);
  }
}

@include media-breakpoint-up(sm) { // 
  @each $size in $sizes {
    .fs-sm-#{$size} {
      font-size: rem($size);
    }
  }
}

@include media-breakpoint-up(lg) { // 
  @each $size in $sizes {
    .fs-lg-#{$size} {
      font-size: rem($size);
    }
  }
}

.lh-1 { line-height: 1 }
.lh-30 { line-height: (30/18) }

// From photoshop to CSS: just divide letter spacing from photoshop by 1000
.ls--17 { letter-spacing: (17 / 1000) * -1em }
.ls--9 { letter-spacing: (9 / 1000) * -1em }
.ls--5 { letter-spacing: (5 / 1000) * -1em }
.ls-17 { letter-spacing: (17 / 1000) * 1em }
.ls-30 { letter-spacing: (30 / 1000) * 1em }
.ls-75 { letter-spacing: (75 / 1000) * 1em }
.ls-100 { letter-spacing: (100 / 1000) * 1em }
.ls-150 { letter-spacing: (150 / 1000) * 1em }


.h-68 {
	font-size: rem(48);
	@include media-breakpoint-up(md) {
		font-size: rem(68);
	}
}

.h-yellow {
	span {
		color: $yellow
	}
}

.text-70 { color: rgba($grey-blug, .7) }
.text-80 { color: rgba($grey-blug, .8) }

.text-blug { color: $grey-blug }

.h-404 {
	font-size: rem(120);
	@include media-breakpoint-up(lg) {
		font-size: rem(280);
	}
}