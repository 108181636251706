// ¿Es necesario esto?
.validation-form {
  .invalid-feedback{
    display: block;
  }
}

.recaptcha-form {
	.invalid-feedback {
    display: block;
		background-color: $danger;
		position: relative;
		left: 0;
		color: #fff;
		z-index: 1;
		padding: 0 rem(12);
		&:before {
			bottom: 100%;
			left: 0;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			pointer-events: none;
			border-color: rgba(194, 225, 245, 0);
			border-bottom-color: $red;
			border-width: rem(5);
			position: absolute;
		}
  }
	.custom-checkbox {
		.invalid-feedback {
			margin-left: -1 * $custom-control-gutter;
			width: calc(100% + #{$custom-control-gutter});
		}
	}
}



.input-white {
	border: none;
	&::placeholder {
		color: rgba($gray-800, .4);
	}
}

option[value=""][disabled] {
  display: none;
}

.custom-select--white {
	background: transparent $custom-select-indicator--white no-repeat right $custom-select-padding-x center;
	background-size: $custom-select-bg-size;
	border-color: #fff;
	color: #fff;
	height: auto;
	margin-bottom: rem(12);
	padding: rem(12) rem(17);
	&.error{
		margin-bottom: 0;
	}
	& + span.invalid-feedback {
		margin-bottom: rem(12);
	}
	option {
		background-color: #2b2b2b;
		color: #fff;
	}
}

.input-yellow {
	background-color: transparent;
	border-color: #fff;
	color: #fff;
	margin-bottom: rem(12);
	padding: rem(12) rem(17);
	&::placeholder {
		//color: rgba($gray-800, .4);
	}
	&.error{
		margin-bottom: 0;
	}
	& + span.invalid-feedback {
		margin-bottom: rem(12);
	}
	&.custom-select {
		height: auto;
	}
}

.newsletter-row {
	margin-bottom: rem(4);
}

.btn-configurator {
	height: rem(50);
	@include media-breakpoint-up(xl) {
		margin-top: rem(33)
	}
}

.input-configurator {
	height: rem(50);
	padding-left: rem(17);
}

.aside-form {
	padding: rem(38) rem(20);
}

.textarea {
	resize: none
}

.custom-control-label--dark {
	&:before {
		border-color: $gray-800
	}
	
}

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label--dark {
    &::after {
      background-image:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$gray-800}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");
    }
  }
}

.form-contact {
	.btn-send {
		border-color: #fff;
		color: #fff;
	}
	.form-control {
		&:focus {
			background-color: transparent;
			color: #fff;
		}
		&::placeholder {
			color: #fff;
		}
	}
}

.bg-yellow {
	.custom-select {
		color: $gray-800;
		&:focus {
			background-color: #fff;
		}
	}
}