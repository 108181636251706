#cookies-msg {
	background-color: $gray-900;
	bottom: rem(24);
	color: #fff;
	left: rem(24);
  position: fixed;
	right: rem(24);
  z-index: $zindex-tooltip;
	@include media-breakpoint-up(md) {
		bottom: rem(32);
		left: auto;
		max-width: 480px;
		right: rem(32);
	}
}

.video-no-cookies {
  position: relative;
  width: 100%;
  padding-top: percentage(9 / 16);
  background-repeat: no-repeat;
  &::before{
    position: absolute;
    z-index: 10;
    background: rgba(0,0,0,.75);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
  & span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    padding: .5rem;
    color: white;
    z-index: 11;
    text-align: center;
  }
}

.bg-info--arrow {
	position: relative;
	&:before {
		bottom: 100%;
		left: 0;
		border: solid transparent;
		content: "";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(194, 225, 245, 0);
		border-bottom-color: $info;
		border-width: rem(5);
	}
}

