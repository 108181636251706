footer {
	border-top: rem(6) solid $yellow;
	padding-top: rem(29);
}

.list-nav-footer {
	@include media-breakpoint-down(md) {
		li {
			&:not(:last-child) {
				margin-bottom: rem(8);
			}
		}
	}
	@include media-breakpoint-up(lg) {
		li {
			&:not(:last-child) {
				margin-right: rem(25);
			}
		}
	}
	@include media-breakpoint-up(xl) {
		li {
			&:not(:last-child) {
				margin-right: rem(50);
			}
		}
	}
}

.input-newsletter {
	margin-right: rem(5);
}

.btn-newsletter {
	min-width: rem(120);
}

.footer-bottom {
	border-top: rem(1) solid #888;
	margin-top: rem(27);
	padding-top: rem(21);
	padding-bottom: rem(21);
}

.logo-footer { margin-bottom: rem(34) }

.list-footer {
	@include media-breakpoint-down(md) {
		li {
			&:not(:last-child) {
				margin-bottom: rem(8);
			}
		}
	}
	@include media-breakpoint-up(md) {
		li {
			&:not(:last-child) {
				margin-right: rem(22);
			}
		}
	}
	@include media-breakpoint-up(lg) {
		li {
			&:not(:last-child) {
				margin-right: rem(44);
			}
		}
	}
}

