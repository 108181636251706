.lazyframe[data-vendor="youtube"],
.lazyframe[data-vendor="youtube_nocookie"] {
  font-family: Roboto, Arial, Helvetica, sans-serif;

/*   .lazyframe__title {
    color: rgb(238, 238, 238);
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 18px;
    text-shadow: rgba(0, 0, 0, .498039) 0 0 2px;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: color .1s cubic-bezier(.4, 0, 1, 1);

    &:hover {
      color: #fff;
    }

    &::before {
      content: "";
      display: block;
      background: linear-gradient(rgba(0, 0, 0, .2), transparent);
      height: 98px;
      width: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

  } */

  &::before {
		background-color: rgba($gray-800, .5);
    padding-top: 56.25%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: rem(108);
    height: rem(108);
    margin-left: rem(-54);
    margin-top: rem(-54);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 336 336'%3E%3Cpath fill='%23ffe500' d='M262.6,164.6L111.4,89c-1.1-0.6-2.2-0.6-3.4,0s-1.7,1.7-1.7,2.8v151.8c0,1.1,0.6,2.2,1.7,2.8c0.6,0.6,1.1,0.6,1.7,0.6c0.6,0,1.1,0,1.7-0.6l151.8-75.6c1.1-0.6,1.7-1.7,1.7-3.4C264.9,165.8,263.8,165.8,262.6,164.6z'/%3E%3Cpath fill='%23ffe500' d='M168,0C75.6,0,0,75.6,0,168s75.6,168,168,168s168-75.6,168-168S260.4,0,168,0z M168,329.3C79,329.3,6.7,257,6.7,168S79,6.7,168,6.7S329.3,79,329.3,168S257,329.3,168,329.3z'/%3E%3C/svg%3E");
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    border: none;
		transition: all .3s ease-in-out;
    z-index: 4;
  }
  &:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 336 336'%3E%3Cpath fill='%23b3a000' d='M262.6,164.6L111.4,89c-1.1-0.6-2.2-0.6-3.4,0s-1.7,1.7-1.7,2.8v151.8c0,1.1,0.6,2.2,1.7,2.8c0.6,0.6,1.1,0.6,1.7,0.6c0.6,0,1.1,0,1.7-0.6l151.8-75.6c1.1-0.6,1.7-1.7,1.7-3.4C264.9,165.8,263.8,165.8,262.6,164.6z'/%3E%3Cpath fill='%23b3a000' d='M168,0C75.6,0,0,75.6,0,168s75.6,168,168,168s168-75.6,168-168S260.4,0,168,0z M168,329.3C79,329.3,6.7,257,6.7,168S79,6.7,168,6.7S329.3,79,329.3,168S257,329.3,168,329.3z'/%3E%3C/svg%3E");
    opacity: 1;
  }

}