@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-loader-path: "../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-arrow {
	background: $gray-800;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: rem(50);
	width: rem(50);
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 1;
	&:hover, &:focus {
		outline: none;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
	&:before {
		display: block;
		height: rem(13);
		opacity: $slick-opacity-default;
		width: rem(16);
	}
}

.slick-prev {
    left: -25px;
    &:before {
			content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNDMgMTE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEuMSw1NC40Yy0xLDIuMy0xLDQuOSwwLDcuMmMwLjUsMS4xLDEuMiwyLjIsMi4xLDMuMWw0OC4xLDQ4LjFjMS43LDEuOCw0LjEsMi43LDYuNiwyLjdjMi41LDAsNC45LTEsNi42LTIuNwoJYzMuNi0zLjYsMy42LTkuNSwwLTEzLjJMMzIuMiw2Ny4zaDEwMS4xYzAsMCwwLDAsMCwwYzUuMSwwLDkuMy00LjIsOS4zLTkuM2MwLTUuMS00LjItOS4zLTkuMy05LjNIMzIuMmwzMi4zLTMyLjIKCWMzLjYtMy42LDMuNi05LjUsMC0xMy4yYzAsMCwwLDAsMCwwYy0zLjYtMy42LTkuNS0zLjYtMTMuMiwwTDMuMSw1MS40QzIuMiw1Mi4yLDEuNSw1My4zLDEuMSw1NC40TDEuMSw1NC40eiIvPgo8L3N2Zz4K');
    }
}

.slick-next {
    right: -25px;
    &:before {
			content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNDMgMTE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0MS45LDU0LjRjLTAuNS0xLjEtMS4xLTIuMS0yLTNMOTEuNywzLjJjLTMuNi0zLjYtOS41LTMuNi0xMy4yLDBjMCwwLDAsMCwwLDBjLTMuNiwzLjYtMy42LDkuNSwwLDEzLjIKCWwzMi4zLDMyLjJIOS43Yy01LjEsMC05LjMsNC4yLTkuMyw5LjNjMCw1LjEsNC4yLDkuMyw5LjMsOS4zYzAsMCwwLDAsMCwwaDEwMS4xTDc4LjYsOTkuNmMtMy42LDMuNi0zLjcsOS41LDAsMTMuMgoJYzEuOCwxLjgsNC4xLDIuNyw2LjYsMi43YzIuNSwwLDQuOC0xLDYuNi0yLjdsNDguMS00OC4xYzAuOS0wLjksMS42LTEuOSwyLjEtMy4xQzE0Mi45LDU5LjMsMTQyLjksNTYuNywxNDEuOSw1NC40TDE0MS45LDU0LjR6Ii8+Cjwvc3ZnPgo=');
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
	list-style: none;
	display: flex;
	justify-content: center;
	text-align: center;
	padding: rem(25) 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: rem(10);
		width: rem(10);
		margin: 0 rem(8);
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: $gray-800;
			border-radius: 50%;
			display: block;
			height: rem(10);
			width: rem(10);
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
			}
    }
		&.slick-active {
			button {
				background-color: $yellow;
			}
		}
	}
}

