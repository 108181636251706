.cms {
	p {
		&:last-child {
      margin-bottom: 0;
    }
	}
  ul, ol {
    text-align: left;
    margin-bottom: 1rem;
    > li {
      &:last-child {
        margin-bottom: 0;
      }
      ul, ol {
        margin-top: .5rem;
        margin-bottom: 0;
      }
    }  
    > li {
      margin-bottom: .5rem
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
	ul {
		list-style-type: none;
		padding-left: rem(23);
		li {
			&:before {
				content: '';
				background-color: $gray-800;
				display: block;
				height: rem(3);
				margin-left: rem(-18);
				margin-top: rem(12);
				position: absolute;
				width: rem(3);
			}
		}
	}
}