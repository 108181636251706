.list-services {
	li {
		@include media-breakpoint-up(lg) {
			&:nth-child(odd) {
				padding-left: rem(37);
				padding-right: rem(30);
			}
			&:nth-child(even) {
				padding-left: rem(30);
				padding-right: rem(37);
			}
		}
		@include media-breakpoint-up(xl) {
			&:nth-child(odd) {
				padding-left: rem(74);
				padding-right: rem(60);
			}
			&:nth-child(even) {
				padding-left: rem(60);
				padding-right: rem(74);
			}
		}
	}
/* 	@media (min-width: 481px) and (max-width: 575px) {
		padding-left: rem(48); 
		padding-right: rem(48);
	}
	

	} */
}

.service {
	border-bottom: rem(3) solid $yellow;
	.ratio__content {
		&:before {
			content: '';
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
	&__content {
		padding-bottom: rem(26);
		&:after {
			color: $yellow;
			font-family: 'Oswald';
			font-size: 1rem;
			font-weight: 600;
			letter-spacing: (30 / 1000) * 1em;
			text-transform: uppercase;
		}
		&:lang(en) {
			&:after {
				content: 'View more';
			}
		}
		&:lang(es) {
			&:after {
				content: 'Ver más';
			}
		}
		.cms {
			line-height: 1.75;
			margin-bottom: rem(30);
		}
	}
}

.service-interest {
	&__content {
		&:before {
			content: '';
			background-color: rgba($grey-blug, .7);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}