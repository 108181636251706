.list-product-category {
	padding-left: rem(40);
	padding-right: rem(46);
	position: relative;
	width: 100%;
	&:before {
		content: '';
		background-color: $yellow;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: calc(100% - #{rem(104)});
	}
	li {
		position: relative;
	}
	a {
		border-bottom: 1px solid $gray-800;
		display: block;
		padding: rem(14) rem(9);
	}
}

.list-product-detail {
	&__content {
		border: 1px solid #d8d8d8;
		padding: rem(24) rem(16);
	}
}

.table-sub {
	font-size: rem(16);
	th {
		text-transform: uppercase;
	}
	td {
		background-color: #fff;
	}
}

.col-sub {
	@include media-breakpoint-up(xl) {
		padding-left: rem(55);
		padding-right: rem(40);
	}
}

.col-subsub {
	@media (min-width: 481px) and (max-width: 575px) {
		padding-left: rem(48); 
		padding-right: rem(48);
	}
	@include media-breakpoint-up(lg) {
		&:nth-child(odd) {
			padding-left: rem(78);
			padding-right: rem(48);
		}
		&:nth-child(even) {
			padding-left: rem(48);
			padding-right: rem(78);
		}
	}
}

.block-type-3{
  h3{
    font-size: 1.7rem;
    @media (max-width: 1199px) {
      font-size: 1.4rem;
    }
  }
}

.aside-form-lateral{
  .overlay{
    cursor: pointer;
    max-width: 350px;
    margin: 0 auto;
    background-color: rgba(29,31,36,0.7);
    &:hover:before {
      background-color: rgba(29,31,36,0.9);
    }
    span{
      font-size: 13px;
      top: 1rem;
    }
    h4{
      font-size: 18px;
      color: #fff;
      text-decoration: none;
      position: absolute;
      top: 33%;
      padding: 1rem;
    }
    p{
      position: absolute;
      bottom: 0;
      right: 1rem;
      color: #fff;
    }
  }
}