.card-about {
	background-color: $yellow;
	padding: rem(19) rem(13);
	.cms {
		line-height: (35/18);
	}
}

.col-presence-1 {
	@include media-breakpoint-up(xl) {
		flex-basis: rem(443 + 15 + 83 + 15);
		max-width: rem(443 + 15 + 83 + 15);
		padding-left: rem(83 + 15);
	}
}

.col-presence-2 {
	@include media-breakpoint-up(xl) {
		padding-right: rem(75 + 15);
	}
}


.col-about {
	@include media-breakpoint-up(xl) {
		flex-basis: calc((100% / 3) - #{rem(64)});
		max-width: calc((100% / 3) - #{rem(64)});
	}
}