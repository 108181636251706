.ratio {
	display: flex;
	position: relative;
	&:before {
		content: '';
	}
}

.ratio__content {
	position: relative;
	width: 100%;
}

.ratio-1-1 {
	&:before {
		padding-bottom: 100%;
	}
}


.ratio-2-1 {
	&:before {
		padding-bottom: (1/2) * 100%;
	}
}

.ratio-16-9 {
	&:before {
		padding-bottom: (9/16) * 100%;
	}
}

.ratio-13-15 {
	&:before {
		padding-bottom: (15/13) * 100%;
	}
}

.ratio-20-21 {
	&:before {
		padding-bottom: (21/20) * 100%;
	}
}

.ratio-map {
	&:before {
		padding-bottom: (3/4) * 100%;
	}
	@include media-breakpoint-up(sm) {
		&:before {
			padding-bottom: (9/16) * 100%;
		}
	}
	@include media-breakpoint-up(lg) {
		&:before {
			padding-bottom: 29%;
		}
	}
}

.ratio-404 {
	&:before {
		padding-bottom: calc(100vh - #{rem(122)});
	}
}