// SLIDE
.home-carousel {
  .carousel-cell{
    height: 500px;
    width: 100%;
    position: relative;
    .text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background: rgba(0,0,0,.3);
      padding: 15px;
    }
  }
}

.card-home {
	&__body {
		padding: rem(26);
	}
	&__footer {
		border-top: rem(1) solid rgba(255, 255, 255, .3);
		padding: rem(19) rem(26);
	}
}

.list-home-count {
	counter-reset: section;
	li {
		&:not(:last-child) {
			margin-bottom: rem(102);
		}
	}
	&__title {
		min-height: rem(140);
		margin-right: rem(54);
		&:before {
			counter-increment: section;
			content: "0" counter(section);
			color: $yellow;
			font-family: 'Oswald';
			font-size: rem(140);
			line-height: 1;
			position: absolute;
		}
	}
	h2 {
		letter-spacing: (21 / 1000) * 1em;
		font-size: rem(32);
		margin-bottom: rem(25);
		padding-left: rem(62);
		position: relative;
	}
}