.slider-full {
	.slick-arrow {
		background-color: transparent
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}

.btn-slider-full {
	min-width: rem(180);
}

.slider-in {
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}

.slider-arrows-custom {
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
	@include media-breakpoint-up(xl) {
		.slick-prev {
			left: rem(-67);
		}
		.slick-next {
			right: rem(-67);
		}
	}
}