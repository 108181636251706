.text-decoration-none { text-decoration: none }

.link-float {
	margin-left: rem(-7);
	margin-bottom: rem(22);
	max-width: 100%;
	position: absolute;
}

.overlay {
	&:before {
		content: '';
		background-color: rgba($grey-blug, .4);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: background-color .15s ease-in-out;
	}
	&:hover {
		&:before {
			background-color: rgba($grey-blug, 0);
		}
	}
}

.link-01 {
	&__overlay {
		position: relative;
		&:before {
			content: '';
			background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 1;
			transition: opacity .15s ease-in-out;
		}
	}
	&__title {
		border-bottom: rem(2) solid $yellow;
		padding-bottom: rem(13);
		padding-left: rem(18);
		position: relative;
		margin-top: rem(-21);
		transition: color .15s ease-in-out;
	}
	&:hover {
		.link-01__overlay {
			&:before {
				opacity: .7;
			}
		}
		.link-01__title {
			color: $yellow;
		}
	}
}

.link-hover {
	position: relative;
	&:after {
		background-color: rgba(0, 0, 0, .4);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: background-color .15s ease-in-out;
	}
	&:hover {
		&:after {
			background-color: rgba(0, 0, 0, 0);
		}
	}
}

.from-dark-to-light {
	color: $gray-800;
	&:hover, &:focus {
		color: lighten($gray-800, 30%);
	}
}

.from-dark-to-yellow {
	color: $gray-800;
	&:hover, &:focus {
		color: $yellow;
	}
}

.img-scale {
	&.ratio,
	.ratio {
		overflow: hidden;
	}
	img {
		transition: transform .2s ease-in-out;
	}
	&:hover, &:focus {
		img {
				transform: scale(1.05);
		}
	}
}

.link-tecnologia {
	overflow: hidden;
	h2 {
		transition: color .2s ease-in-out;
	}
	&:hover, &:focus {
		h2 {
			color: $yellow;
		}
	}
}

.link-product-category {
	color: $gray-800;
	position: relative;
	&:before {
		content: '';
		background-color: $gray-800;
		bottom: 0;
		left: 0;
		position: absolute;
		top: 0;
		transition: all .3s ease-in-out;
		width: 0;
	}
	span {
		position: relative;
	}
	&:hover, &:focus {
		color: $yellow;
		&:before {
			width: 100%;
		}
	}
}