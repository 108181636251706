.header-top {
	padding: rem(13) 0;
}

.list-header-top {
	li {
		&:not(:last-child) {
			margin-right: rem(38);
		}
	}
}

.btn-lang {
	color: #fff;
	&:not(.collapsed) {
		color: darken(#fff, 10%);
		.icon {
			transform: rotate(180deg)
		}
	}
}

.btn-nav--collapse {
	height: rem(27);
	width: rem(27);
	&:not(.collapsed) {
		.icon {
			transform: rotate(180deg)
		}
	}
}

.list-lang {
	@include media-breakpoint-up(sm) {
		li {
			&:not(:last-child) {
				margin-right: rem(20);
			}
		}
	}
}

.lang-collapse {
	@include media-breakpoint-down(sm) {
		background-color: $dark;
		position: absolute;
		right: 0;
		top: rem(34);
		width: rem(48);
		z-index: 1;
		a {
			height: rem(48);
			width: rem(48)
		}
	}
	.active {
		color: $yellow !important;
		pointer-events: none;
		cursor: default;
	}
}

.header-bottom {
	padding: rem(11) 0;
}

.nav-logo {
	@include media-breakpoint-down(md) {
		margin-right: auto
	}
}

.btn-nav {
	position: relative;
	span {
		background-color: #706f6f;
		height: rem(2);
		width: rem(22);
		display: block;
		&:before, &:after {
			content: '';
			background-color: #706f6f;
			display: block;
			height: rem(2);
			width: rem(22);
			position: absolute;
		}
		&:before {
			transform: translateY(rem(7))
		}
		&:after {
			transform: translateY(rem(-7))
		}
	}
}

.btn-navclose {
	position: absolute;
	right: rem(15);
	top: rem(58);
}


.nav-main {
	@include media-breakpoint-down(md) {
		background-color: $yellow;
		bottom: 0;
		position: fixed;
		top: 0;
		transform: translateX(100%);
		transition: transform .3s ease-in-out;
		right: 0;
		width: 21rem;
		z-index: 2;
	}
}

.list-nav {
	@include media-breakpoint-down(md) {
		padding: rem(144) 1.5rem 1.5rem 1.5rem;
		li {
			padding: rem(8) 0;
		}
	}
	@include media-breakpoint-up(lg) {
		> li {
			&:not(:last-child) {
				margin-right: rem(25);
			}
			&:hover {
				> .list-collapse {
					visibility: visible;
					animation: dropdown-show .2s
				}
			}
		}
	}
	@include media-breakpoint-up(xl) {
		> li {
			&:not(:last-child) {
				margin-right: rem(50);
			}
		}
	}
}

.list-nav-2 {
	@include media-breakpoint-down(md) {
		padding: 0 0 0 1rem;
		li {
			padding: rem(8) 0;
		}
	}
	@include media-breakpoint-up(lg) {
		background-color: $yellow;
		margin-left: rem(-16);
		padding: rem(24) 0 rem(8) 0;
		a {
			display: block;
			padding: rem(8) rem(16);
		}
	}
}

.list-collapse {
	@include media-breakpoint-up(lg) {
		position: absolute;
		z-index: 1;
		visibility: hidden;
	}
}

.btn-color { color: #706f6f }

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000;
	transition: opacity .15s linear;
	visibility: hidden;
}

.modal-content--search {
	background-color: $yellow;
	border: none;
	border-radius: 0;
}

.form-search {
	border-bottom: 1px solid #fff;
}

.form-control--search {
	background-color: transparent;
	border: none;
	&:focus {
		background-color: transparent;
	}
}

.opened {
	@include media-breakpoint-down(md) {
		overflow: hidden;
		.nav-main {
			transform: none;
			overflow: auto;
		}
		.offcanvas-backdrop {
			opacity: .65;
			visibility: visible;
		}
	}
}

@keyframes dropdown-show {
  from {
    transform: translateY(#{rem(8)});
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}